
import throttle from 'lodash.throttle';
import { LibButton } from '@adidas-com-front/ui-library';
import { getLinkType, feedProductClick, feedProductView } from '../track';
import productList from './components/productList.vue';
import mobileProductList from './components/mobileProductList.vue';
import { handleClickLink, handleHref } from '~/components/clp/util';
import { getImgUrl } from '~/utilities/helper/urlHelper';

export default {
  name: 'FeedProduct',
  components: {
    LibButton,
    productList,
    mobileProductList
  },
  props: {
    detail: {
      type: [Object, Array],
      default: () => {}
    },
    sensorInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isMobile: true,
      isLoad: false
    };
  },
  computed: {
    imgInfo() {
      return function (item) {
        return this.isMobile
          ? {
              img: getImgUrl(item.mobImgUrl, 500),
              tag: item.mobSeoTag
            }
          : {
              img: getImgUrl(item.pcImgUrl, 500),
              tag: item.pcSeoTag
            };
      };
    },
    optLink() {
      return function (item, type) {
        if (type === 'Image') {
          return item.photoLink || item.link;
        } else if (type === 'Button') {
          return item.buttonLink || item.link;
        } else {
          return '';
        }
      };
    },

    handleUrl() {
      return function (link) {
        if (!link) { return 'javascript:void(0);'; }
        return handleHref(link);
      };
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    this.$on(
      'hook:beforeDestroy',
      throttle(this.$utils.device.onPageScroll(this.sensrosOnScroll), 500)
    );
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // 滚动事件上报nbanner view
    sensrosOnScroll() {
      const { scrollTop } = this.$utils.device.getScrollTop();
      const { bannerId, bannerName } = this.sensorInfo;

      const param = {
        component_code: bannerId,
        subject: bannerName,
        series_name: '',
        article_number_list: this.getArticleIdList().join('|')
      };

      try{
        // 124 = header height
        if(scrollTop < this.$refs.feedRef.offsetTop + this.$refs.feedRef.clientHeight + 124 && scrollTop > this.$refs.feedRef.offsetTop - window.innerHeight + 124) {
          feedProductView(param);
        }
      } catch(e) {}
    },

    getArticleIdList() {
      let totalList = [];

      this.detail.map(item => {
        totalList = totalList.concat(this.isMobile ? item.products.slice(0,2) : item.products);
        return false;
      });

      return totalList.map(item => {
        return item.articleId;
      });
    },

    sensorClickTrack(type, data, position) {
      const { bannerId, bannerName } = this.sensorInfo;
      const { photoLink = "", buttonLink = "", brandName = "", link = "" } = data;
      const finalPhotoLink = photoLink || link;
      const finalButtonLink = buttonLink || link;
      const clickType = getLinkType(type === 'Image' ? finalPhotoLink : finalButtonLink);
      const param = {
        component_code: bannerId,
        subject: bannerName,
        series_name: brandName,
        click_type: clickType,
        position: position
      };
      if(clickType === 'PDP') {
        param.article_number = data.articleId;
      } else if(clickType === 'PLP' || clickType === 'SPLP') {
        param.to_plp_url = type === 'Image' ? photoLink || link : buttonLink || link;
      } else {
        param.to_url = type === 'Image' ? photoLink || link : buttonLink || link;
      }
      feedProductClick(param);
    },
    linkRoute(item, type, position) {
      if (!this.optLink(item, type)) { return; }
      const _item = { ...item, link: this.optLink(item, type) };
      handleClickLink.call(this, _item);
      this.sensorClickTrack(type, item, position);
    },
    handleResize() {
      this.isMobile = window.innerWidth < 720;
    },
    onLoad() {
      this.isLoad = true;
    },
    transmit() {
      return this.$parent.$options.name;
    }
  }
};
