
import { LibProductCard } from '@adidas-com-front/ui-library';
import { trackClpPdpClick } from '../../../clp/track/index';

export default {
  name: 'MobileProductList',
  components: { LibProductCard },
  props: {
    info: {
      type: Object
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeBanner: 0,
      swiperProgress: 0,
      slidePerView: 0,
      benefitDescriptionHeight: '',
      swiperNavigationTop: '0px',
      productListData: []
    };
  },
  computed: {

  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    sensorClickTrack(item, index) {
      // 判断当前父组件做埋点
      const parent = this.$parent.transmit();
      if (parent === 'CLP') {
        const data = [
          this.$route.query.contentId || null,
          item.articleId,
          null,
          item.brandName,
          item.category,
          item.genderShow
        ];
        trackClpPdpClick(...data);
      } else {
        const link = `/pdp?articleId=${item.articleId}`;
        // index 从2开始算，1为外层的封面item
        this.$emit('sensorTrack', 'Image', { ...item, photoLink: link }, index + 1 + 1);
      }
    },
    handleResize() {
      let slidePerView;
      this.slidePerView = slidePerView;

      if (!document.getElementsByClassName('product-list')[0]) { return; }
      // 设置完轮播图属性 动态获取的图片高度确定导航的绝对位置
      setTimeout(() => {
        const imgDom = document.getElementsByClassName('product-list')[0];
        if (!imgDom) { return; }
        this.swiperNavigationTop =
          ((imgDom.clientWidth - 4) / 3 + 72) / 2 - 16 + 'px';
      }, 0);
    }
  }
};
